/* stylesheet for rock paper scissors game */

#containerRps button {
    transform: scale(50%); /* we are using transform, because pictures are large */
    margin: auto;
    transition: 0.5s ease-out;
}

#containerRps button:hover{
    transform: scale(60%);
}

#results {
    font-family: "Poppins";
    font-size: 18px;
    color: rgb(122, 122, 122);
}
#rematch {
    background-color: #f9004d;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding: 10px;
    border-radius: 20px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    animation: fadeInOpacity 0.75s forwards;

}

@keyframes fadeInOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}