/*stylesheet for testimonials slider */

.carousel-item img {
    width: 100%;
}

.carousel-caption {
    top:50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;

}

.carousel-caption img {
    width: 60px;  
    height: 60px; 
    display: block;
}

@media only screen and (max-width: 1150px) {
    .carousel-item p {
    font-size: 12px;
    }

    .carousel-item i{
        font-size:12px;
    }

    .carousel-caption img {
        width: 40px; 
        height: 40px; 
      
}
}

@media only screen and (max-width: 840px) {
    .carousel-item img {
        width:100%;
        height: 400px;
        opacity: 0; /* we get rid of the background image in smaller screens for better text display */
    }

    .carousel-caption img {
        opacity: 1;
        width: 30px;  
        height: 30px; 
    }
}

@media only screen and (max-width: 550px) {
    .carousel-item img {
        width:100%;
        height: 400px;
        opacity: 0; 
    }

    .carousel-caption img {
        opacity: 1;
        width: 30px;  
        height: 30px; 
    }
  }

/*I commented these out, you can uncomment them if you want to set buttons and indicator hidden */

/* .carousel-control-next-icon, .carousel-control-prev-icon {
    visibility: hidden;
} */

/* .carousel-indicators {
    visibility: hidden;
} */