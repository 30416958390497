/*stylesheet for About section */

/*this animation is for the section to show after clicking scroll down button on hero section */

.fade-in {
    opacity: 0;
    transition: opacity 1.5s ease-out; /* Adjust duration as needed */
  }
  
  .fade-in.visible {
    opacity: 1;
  }

.about-container{
    padding: 16px;
    background-color: rgb(27, 27, 27);
    
}
.AboutImage{
    overflow: visible;
}


.about-container h2{
    text-align: center;
    font-size: 48px;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    position: relative;
    
}

.about-container h2::after {
    content: "";  /* This is required for the pseudo-element to show */
    display: block;
    width: 40%;  /* Adjust this to change the width of the underline */
    height: 5px;
    background-color: #f9004d;
    position: absolute;
    bottom: 0;
    left: 50%;  /* This, combined with the transform property, centers the underline */
    transform: translateX(-50%);
}

#about-me{
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.about-me p{
    color: rgb(122, 122, 122);
}

/* we are using id for about sections paragraph, so we can avoid style overrides */

#aboutparagraph{
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}



@media only screen and (max-width: 1600px) {
    
    #aboutparagraph{
        font-size: 14px ;
    }
  }

   /* here we change the positioning of the about image, cause it easily gets too small in certain width */
  @media only screen and (max-width: 1400px) {
    
    .AboutImage{
        width: 50%;
    }
    .imgColumn{
        display: flex;
        justify-content: center;  /* Horizontally center the content */
        align-items: center; 
    }
  } 

  /* we use this to get back to defaul styling in image */
  @media only screen and (max-width: 500px) {
    
    .AboutImage{
        min-width: 100%;
    }
  } 