/* stylesheet for projects section */

.projectContainer {
    background-image: linear-gradient( rgba(27,27,27), rgba(0,0,0));
    padding-top: 40px;
    text-align: center;
    opacity: 0;
    transform: translateY(-50px); /* we set inital position, so the section fades and drops in view*/
}

.projectContainer h2 {
    padding: 7px;
    color: rgb(122, 122, 122);
    font-size: 48px;
    background: linear-gradient(to right, hsl(0, 0%, 30%) 0, #f9004d 10%, hsl(0, 0%, 30%) 20%);
    background-size: 200% 100%; 
    background-clip: text;
    -webkit-text-fill-color: transparent; /* For webkit browsers, creates the gradient text effect */
    animation: shine 4s infinite linear;
}

.projectContainer h4 {
    font-size: 36px;
    transition: 0.5s ease-out;
}

.projectContainer h4:hover{
    color: #fff;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
    letter-spacing: 3px;
}

.projectRow {
  margin-top: 80px;
}

.projectInfo {
  opacity: 0;
  transform: translateY(-50px); /* we set inital position, so the section fades and drops in view*/
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 40px;
  
}
.projectInfo p {
  padding: 8px;
  border: solid 1px rgba(122,122,122);
  border-radius: 8px;
  color: rgba(122,122,122);
  box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem rgba(122, 122, 122, 0.25);
}

.projectInfo a {
  color: rgba(122,122,122);
}

.projectInfo a:hover {
  opacity: 0.7;
}

@keyframes shine {
    0% {
      background-position: 100% 0;
    }
    60% {
      background-position: -100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  @keyframes slideFadeIn {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.slideFadeInEffect {
    animation: slideFadeIn 2s forwards;
}