/* stylesheet for calculator */

.calculator {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: auto 1fr;
    max-width: 20rem;
    margin: 2rem auto; /* centers the calculator vertically and horizontally */
    border-radius: 10px;
    overflow: hidden;
    background-color: rgb(44, 44, 44);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.calculator_display {
    height: 6rem;
    font-size: 5rem;
    max-width: 20rem;
    text-align: end;
    background-color: rgba(0, 0, 0, 0.75); 
    color: rgb(255, 255, 255);
    padding: 0.5rem;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    line-height: 1;
}

.calculator_keys {
    padding:0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2px;
}

.calculator_key {
    border-style: none;
    padding-block-start: 0.6rem; 
    padding-block-end: 0.6rem; 
    text-align: center;
    font-size: 2rem;
    background-color:rgb(255, 255, 255);
    border-radius: 4rem;
    width: 4rem; /* changed from inline-size for better cross-browser support */
    margin:0.25rem;
    
}

.calculator_key:hover{
    opacity: 0.75;
}

.calculator_key_operator {
    background-color: rgba(210, 220, 228);

}
.calculator_key_enter {
    grid-column: 4 / 5; /* Start at column #4 and end at column #5 (the very right edge). */
    grid-row: 2 / span 4; /* Start at row #2 and span 4 rows */
    background-color: rgba(254, 97, 72)
}

@media only screen and (max-width: 650px) {
    html {
        font-size: 20px;
    }
  }

@media only screen and (max-width: 400px) {
    html {
        font-size: 15px;
    }
  }

  @media only screen and (max-width: 330px) {
    html {
        font-size: 10px;
    }
  }