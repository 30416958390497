/*this stylesheet contains default styles to be used in application */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap|Poppins:400;700');

@font-face {
  font-family: 'BERLIN';  
  src: url('/public/fonts/Berlin-Bold.woff2') format('woff2'),
       url('/public/fonts/Berlin-Bold.woff') format('woff');
       font-weight: bold;
}
@font-face {
  font-family: 'BERLINXTRA';  
  src: url('/public/fonts/Berlin-ExtraBold.woff2') format('woff2'),
       url('/public/fonts/Berlin-ExtraBold.woff') format('woff');
       font-weight: 800;
}

html {
  scroll-behavior: smooth;
  margin: 0; /* Reset default margin */
}

p{
  font-family: 'Roboto', sans-serif;
  color: white;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

body {
  background-color: black;
}

h1{
  font-family: "BERLINXTRA";
  font-size: 96px;
  color: white;
}
h2{
  color:white;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 36px;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: white;
}

h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(122, 122, 122);
}

button {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

/* we are changing offcanvas closebutton in root level to make change global */
:root .btn-close {
  --bs-btn-close-bg:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f9004d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity:1;

}

