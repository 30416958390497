/*stylesheet for etch a sketch, in projects sections */

#containerSketch {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    background-color: white;
    max-width: 450px;
    height: auto;
    margin: 0 auto;
    border: solid 10px #f9004d;
    border-radius: 15px;
}

.square {
    width: 100%;
    height: 100%;
    padding-top: 100%;
}

.drawn {
    background-color: black;
}
.sketchButtons {
    display: flex;
    justify-content:space-evenly;
}
.sketchButtons button{
    background-color: #f9004d;
    font-family: 'Poppins', sans-serif;;
    color: white;
    display: block;
    margin: 20px 10px 20px 10px;
    padding: 10px;
    border-radius: 20px;
}

.sketchButtons button:hover{
    opacity: 0.75;
}

.clicktodraw p {
    margin-top: 5px;
    color: rgba(122,122,122);
}
