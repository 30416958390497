/*stylesheet for Contact Form */

.formArea h2 {
    font-size: 48px;
    margin-top: 20px;
    transition: 0.5s ease-out;
}

.formArea h2:hover {
    color: #fff;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
    letter-spacing: 3px;
}

.formArea p {
    margin-top: 15px;
}

.form-control {
    background-color:black;
    color: rgba(122,122,122);
}

form label{
    color:rgba(122, 122,122);
}

form label:focus{
    background-color: transparent;
}
.form-control:focus{
    background-color: transparent;
    color: white;
    border-color: #f9004d;
    box-shadow: 0 0 0 0.25rem #f9004d25;
}

/*because we are using floating inputs, additional styling on floating is needed */
.form-floating>.form-control:not(:placeholder-shown)~label {
    color: rgb(147, 147, 147); 
}

.form-floating>.form-control:focus~label::after {
    background-color: transparent;
}

.form-floating>.form-control:focus~label{
    color: rgb(147, 147, 147); 
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
    background-color: transparent;
}

.form-check-input:checked{
    background-color: #f9004d;
    border-color: #f9004d;
}

#formSubmit {
    background-color: #f9004d;
    border-color:#f9004d;
    transition: 0.3s ease-out
}

#formSubmit:hover{
    opacity: 0.75;
}

.thankYouMessage{
    margin-top: 180px;
}

.thankYouMessage p{
    margin:0;
    margin-top: 10px;
    font-weight: bold;
    color: #f9004d;
    display: inline-block;
    vertical-align: top;
    border-right: .15em solid  #f9004d;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    animation: typewriter2 2s steps(44, end) forwards;
}

.termsAndConditions {
    color: white;
}

@keyframes typewriter2 {
    0% {
      width: 0;
    }
    99.99% {
      border-right-color: white; /* Maintain the cursor color till the very end */
    }
    100% {
      width: 100%;
      border-right-color: transparent; /* Hide the cursor right after the last character */
    }
  }