/* stylesheet for social media banner */

.socialMediaIcons{
    display: flex;
    justify-content: center;
}

.socialMediaIcons a{
    margin:16px;
    line-height: 1; 
}

.socialMediaIcons svg{
    width:60px;
    height:60px;
    transition: 0.3s ease;
}

/* in the svgs everything outside of circle is set to empty, so without that class it still fills only the circle*/
.socialMediaIcons svg:hover{
    transform: translateY(-7px);
    fill: #f9004d;
    transition: 0.3s ease;
}

