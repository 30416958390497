/*stylesheet for testimonials section */

.testimonialsContainer{
    opacity: 0;
    transform: translateY(-50px); /* we set inital position, so the section fades and drops in view*/
}

.testimonialsTitle {
    padding-top: 40px;
    text-align: center;
    margin-bottom: 20px;
}

.testimonialsItems h2{
    color: rgba(122,122,122);
    transition: 0.5s ease-out;
}

.testimonialsItems h2:hover{
    color: #f9004d;
    text-shadow: 0 0 10px #f9004d, 0 0 20px #f9004d, 0 0 40px #f9004d;
    letter-spacing: 3px;
}

@keyframes slideFadeIn {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.slideFadeInEffect {
    animation: slideFadeIn 2s forwards;
}