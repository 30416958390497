.video {
  /* Full width and height of the parent container */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-container {
  /* Size and position of the video container */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}