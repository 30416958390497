/*stylesheet for clickable portions of about me section */

.SkillInfo{
    padding-left: 30px;
    padding-right: 30px;
}

.SkillInfo button{
    font-family: 'Poppins', sans-serif; ;
    color: white;
    margin-left: 10px;
    font-size: 24px;
    position: relative;
    padding-bottom: 5px;
}

.SkillInfo button::after{
content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;  /* Initialize border width to 0 */
  height: 2px; /* Border thickness */
  background-color: #f9004d;
  transition: width 0.3s; /* Adjust the duration as per your requirement */
}
.SkillInfo button:hover::after{
    width: 100%;
}

.changingInfo{
    border-top: solid rgb(122, 122, 122);
    color: white;
    margin-top: 20px;
    padding-top: 15px;
} 

/* here we start styling components inside this component */
.InfoBars p {
    white-space: nowrap;
    padding: 0;
}

.InfoBars .table#skillTable {
    text-align: center;
}

.InfoBars .table>:not(caption)>*>* {
    background-color: transparent;
    border-bottom: none;
}

.InfoBars .table>tbody{
    border:none;
}

.InfoBars td{
    width:20%;
}

.education h4{
    margin-bottom: 12px;
}

.education h3 {
    margin-bottom: 16px;
}

@keyframes fadeInOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeInEffect {
    animation: fadeInOpacity 0.75s forwards; 
}

@media only screen and (max-width: 550px) {
    .InfoBars p {
        font-size: 10px;
    }
  }

  @media only screen and (max-width: 550px) {
    .InfoBars p {
        font-size: 8px;
    }

    .InfoBars svg{
      width: 60px;
    }

  }

